@font-face {
  font-family: PP Neue Montreal;
  src: url(./font/PPNEUEMONTREAL-THIN.OTF);
}

body {
  background-color: wheat;
  font-family: "Neue Montreal";
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

.nameHeader_title {
  margin: -0.1em 0 0 -0.04em;
  font-weight: 200;
  font-size: 60px;
  font-family: "PP Neue Montreal";

}

.nameHeader_label {
  margin-top: 5px;
  font-weight: 400;
  font-family: "PP Neue Montreal";
}

.about-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  display: inline-block;
  word-break: break-all;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.info {
  width: 70px;
  height: 100px;
}

a {
  color: inherit;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.5s ease
}

a:hover {
  opacity: 0.5;
}

.project_list {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  display: inline-block;
  word-break: break-all;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: right;
  white-space: nowrap;
}

.project_title {
  font-weight: 200;
  font-size: 30px;
  text-align: right;
}

.project_info {
  margin-top: 5px;
}

li {
  color: inherit;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.5s ease
}

li:hover {
  background-color: initial;
  cursor: pointer;
  opacity: 0.5;

}

.highlighted {
  background-color: initial;
  display: inline;

}
